import { ReactComponent as DekatiLogo } from '../../assets/icons/customers/dekati_logo.svg'
import { ReactComponent as AlisaPankkiLogo } from '../../assets/icons/customers/alisa_pankki_logo.svg'
import { ReactComponent as OixioLogo } from '../../assets/icons/customers/oixio_logo.svg'
import { ReactComponent as HansaMedicalLogo } from '../../assets/icons/customers/hansa_medical_logo.svg'
import { ReactComponent as TypingMasterLogo } from '../../assets/icons/customers/typing_master_logo.svg'
import { ReactComponent as KorkiaLogo } from '../../assets/icons/customers/korkia_logo.svg'
import { ReactComponent as DlaPiperLogo } from '../../assets/icons/customers/dla_piper_logo.svg'
import { ReactComponent as GasmetTechnologiesLogo } from '../../assets/icons/customers/gasmet_technologies.svg'
import { ReactComponent as IOfficeLogo } from '../../assets/icons/customers/ioffice_logo.svg'
import { ReactComponent as UprightProjectLogo } from '../../assets/icons/customers/upright_project_logo.svg'
import { ReactComponent as VainuLogo } from '../../assets/icons/customers/vainu_logo.svg'
import { ReactComponent as PlugitLogo } from '../../assets/icons/customers/plugit_logo.svg'
import { ReactComponent as FingersoftLogo } from '../../assets/icons/customers/fingersoft_logo.svg'
import { ReactComponent as NetumLogo } from '../../assets/icons/customers/netum_logo.svg'
import { ReactComponent as ShowellLogo } from '../../assets/icons/customers/showell_logo.svg'
import { FunctionComponent, SVGProps } from 'react'

export enum Alignments {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Between = 'between',
  Start = 'start',
  End = 'end',
}

export enum ComponentVariants {
  Primary = 'primary',
  DisabledPrimary = 'disabledPrimary',
  Secondary = 'secondary',
  Borderless = 'borderless',
  NegativeSecondary = 'negativeSecondary',
  DisabledSecondary = 'disabledSecondary',
  Light = 'light',
  Dark = 'Dark',
  Grey = 'Grey',
}

export enum Sizes {
  ExtraLarge = 'extraLarge',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  ExtraSmall = 'extraSmall',
}

export const DESKTOP_ANIMATION_SHIFT = -24

export type CustomerCaseItem = {
  id: string;
  prismicId: {
    en: string;
    fi: string;
    sv: string;
  };
  logo: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
  cardImage: string;
}

export const CustomerCasesContent: { [key: string]: CustomerCaseItem } = {
  alisa_pankki: {
    id: 'alisa_pankki',
    prismicId: {
      en: 'ZeCEUxAAAGVkswQi',
      fi: 'ZeCF7BAAAGhkswq1',
      sv: 'ZeCF8hAAAK1kswrQ'
    },
    logo: AlisaPankkiLogo,
    cardImage: '/images/alisa_pankki.png',

  },
  dekati: {
    id: 'dekati',
    prismicId: {
      en: 'ZeBomhAAAANisokY',
      fi: 'ZeCFoxAAAGhkswlq',
      sv: 'ZeCFqhAAAGVkswmA'
    },
    logo: DekatiLogo,
    cardImage: '/images/dekati.png',
  },
  dla_piper: {
    id: 'dla_piper',
    prismicId: {
      en: 'ZeCESBAAALZkswPq',
      fi: 'ZeCF0BAAALZkswo2',
      sv: 'ZeCF3BAAADFkswpq'
    },
    logo: DlaPiperLogo,
    cardImage: '/images/dla_piper.jpeg',
  },
  fingersoft: {
    id: 'fingersoft',
    prismicId: {
      en: 'ZheQkhIAAMJEPTBL',
      fi: 'ZhePdhIAAJxEPSsp',
      sv: 'Zhed-RIAAPZEPW87'
    },
    logo: FingersoftLogo,
    cardImage: '/images/fingersoft.png',
  },
  hansa_medical: {
    id: 'hansa_medical',
    prismicId: {
      en: 'ZeCE7xAAAGhkswZs',
      fi: 'ZeCFkxAAAGhkswks',
      sv: 'ZeCFmRAAAFdkswlI'
    },
    logo: HansaMedicalLogo,
    cardImage: '/images/hansa_medical.jpeg',
  },
  gasmet_technologies: {
    id: 'gasmet_technologies',
    prismicId: {
      en: 'ZeCEbBAAADFkswSZ',
      fi: 'ZeCGJBAAAGhkswvQ',
      sv: 'ZeCGKRAAANtjswvr'
    },
    logo: GasmetTechnologiesLogo,
    cardImage: '/images/gasmet_technologies.png',
  },
  ioffice: {
    id: 'ioffice',
    prismicId: {
      en: 'ZeCEwhAAALZkswYa',
      fi: 'ZeCGVxAAAK1kswzL',
      sv: 'ZeCGXRAAAK1kswzn'
    },
    logo: IOfficeLogo,
    cardImage: '/images/ioffice.jpeg',
  },
  korkia: {
    id: 'korkia',
    prismicId: {
      en: 'Zh9p7BEAAHrPuzV_',
      fi: 'ZheEERIAALJDPPeW',
      sv: 'Zh9u4hEAAIzPu0qN'
    },
    logo: KorkiaLogo,
    cardImage: '/images/korkia.jpeg',
  },
  netum: {
    id: 'netum',
    prismicId: {
      en: 'ZhPctxAAAMEF6P56',
      fi: 'ZhPdBRAAADQG6P_e',
      sv: 'ZhPdVBAAAOsF6QFc'
    },
    logo: NetumLogo,
    cardImage: '/images/netum.jpeg',
  },
  oixio: {
    id: 'oixio',
    prismicId: {
      en: 'ZeCE-hAAAL5kswaC',
      fi: 'ZeCFhBAAAFdkswjm',
      sv: 'ZeCFixAAAL5kswkG'
    },
    logo: OixioLogo,
    cardImage: '/images/oixio.jpeg',
  },
  plugit: {
    id: 'plugit',
    prismicId: {
      en: 'ZeCEgBAAAGhkswT-',
      fi: 'ZeCGNxAAAGhkswwt',
      sv: 'ZeCGQxAAAK1kswxr'
    },
    logo: PlugitLogo,
    cardImage: '/images/plugit.jpeg',
  },
  showell: {
    id: 'showell',
    prismicId: {
      en: 'Zgup7xAAAAOTwOzH',
      fi: 'ZgutDxAAADSTwPrN',
      sv: 'ZguwDxAAAKCTwQiX'
    },
    logo: ShowellLogo,
    cardImage: '/images/showell.jpeg',
  },
  typing_master: {
    id: 'typing_master',
    prismicId: {
      en: 'ZeCD0hAAALZkswG5',
      fi: 'ZeCFuBAAALZkswnC',
      sv: 'ZeCFwhAAAFdkswnw'
    },
    logo: TypingMasterLogo,
    cardImage: '/images/typing_master.jpeg',
  },
  upright_project: {
    id: 'upright_project',
    prismicId: {
      en: 'ZeCEXxAAAFdkswRc',
      fi: 'ZeCGAhAAAFdkswsk',
      sv: 'ZeCGDxAAAL5kswtn'
    },
    logo: UprightProjectLogo,
    cardImage: '/images/upright_project.png',
  },
  vainu: {
    id: 'vainu',
    prismicId: {
      en: 'ZhPe-xAAAMEF6QkW',
      fi: 'ZhPfYBAAAHYG6Qrl',
      sv: 'ZhPf1RAAAOsF6Qz3'
    },
    logo: VainuLogo,
    cardImage: '/images/vainu.jpeg',
  },
}

export const OtherCustomerCasesIds = [
  // alisa_pankki
  'ZeCEUxAAAGVkswQi',
  'ZeCF7BAAAGhkswq1',
  'ZeCF8hAAAK1kswrQ',

  // gasmet_technologies
  'ZeCEbBAAADFkswSZ',
  'ZeCGJBAAAGhkswvQ',
  'ZeCGKRAAANtjswvr',

  // upright_project
  'ZeCEXxAAAFdkswRc',
  'ZeCGAhAAAFdkswsk',
  'ZeCGDxAAAL5kswtn',

  // vainu
  'ZhPe-xAAAMEF6QkW',
  'ZhPfYBAAAHYG6Qrl',
  'ZhPf1RAAAOsF6Qz3',
]

export const SolutionItemIds = {
  expenses: {
    en: 'ZiZrxxAAAOmyV1fq',
    fi: 'ZiZr0hAAABeyV1ge',
    sv: 'ZiZr1xAAABeyV1g4',
  },
  claims: {
    en: 'ZiZsRhAAAOmyV1pA',
    fi: 'ZiZsTRAAABeyV1pk',
    sv: 'ZiZsUhAAANKyV1p7',
  },
  subscriptions: {
    en: 'ZiZsBxAAAOmyV1kP',
    fi: 'ZiZsDBAAAHSzV1kt',
    sv: 'ZiZsERAAAKuzV1lF',
  },
  benefits: {
    en: 'ZnkaHxEAAFWu0gsK',
    fi: 'ZrIDDRIAACIAWNmY',
    sv: 'Zup2phIAAB8AmERS',
  },
  custom: {
    en: 'ZiZtHxAAABeyV14n',
    fi: 'ZiZutRAAAKuzV2Wv',
    sv: 'ZiZuyRAAANKzV2YP',
  },
}

export const CUSTOMER_CARD_WIDTH = 350
export const CUSTOMER_CARD_HEIGHT = 550
export const CUSTOMER_NUMBER = Object.keys(CustomerCasesContent).length
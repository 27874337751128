import tw, { styled } from 'twin.macro'
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion'
import { Description, SubTitle } from '../../common/styled'
import { useIsMobile, useVerticalScrollWithThrottle } from '../../common/hooks'
import { useParams } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import React, { useEffect, useRef, useState } from 'react'
import { getCurrentLocale } from '../../../config/locales'
import { prismicClient } from '../../../config/prismicClient'
import { Alignments, DESKTOP_ANIMATION_SHIFT } from '../../common/constants'
import { Inline, Stack } from '../../common/components/Spacing'
import { ReactComponent as SupportLocalCircle } from '../../../assets/icons/support_local_circle.svg'
import { ReactComponent as SupportLocalCircleReversed } from '../../../assets/icons/support_local_reverced.svg'

const Wrapper = tw(motion.div)`mt-[350px]`

const HandWrapper = tw.div`w-[460px] -ml-[500px]`
const HandCircle = tw(motion(SupportLocalCircle))`-mr-[460px]`
const HandOverlay = tw(motion(SupportLocalCircleReversed))`-mr-[160px] mt-[200px]`
const Hand = tw(motion.img)`w-[460px]`

const MobileWrapper = tw.div`mb-[90px] mt-[150px]`

const MobileCards = styled(motion.img)`
  ${tw`w-[100%] mb-[50px]`}
`
const MobileSubTitle = tw(SubTitle)`text-[32px] leading-[110.5%] z-[3]`
const MobileDescription = tw(Description)`text-[14px]`

// Mobile
const IMAGE_HEIGHT = 350
const MOBILE_IMAGE_SCALE_START = 1.8
const MOBILE_IMAGE_SCALE_END = 1
const FRACTION = 0.2
const HEIGHT_DIFFERENCE =
  IMAGE_HEIGHT * MOBILE_IMAGE_SCALE_START - IMAGE_HEIGHT * MOBILE_IMAGE_SCALE_END
const Y_OFFSET_MAX = -HEIGHT_DIFFERENCE * FRACTION

// Desktop
const DESKTOP_IMAGE_SCALE_START = 2.2
const DESKTOP_IMAGE_SCALE_END = 1
const DESKTOP_IMAGE_HORIZONTAL_SHIFT_START = -560
const DESKTOP_IMAGE_HORIZONTAL_SHIFT_END = 0

const LeftWrapper = styled(motion.div)`
  width: 520px;
`

const RightWrapper = styled(motion.div)`
  margin-left: ${DESKTOP_IMAGE_HORIZONTAL_SHIFT_START}px;
`

const CIRCLE_FINAL_SIZE = 460

type SupportLocalProps = {
  isReady?: boolean
}

export const SupportLocal = ({ isReady }: SupportLocalProps) => {
  const isMobile = useIsMobile()
  const { locale } = useParams()

  const { scrollY } = useVerticalScrollWithThrottle()
  const { scrollY: cardsScrollY } = useScroll()
  const scale = useTransform(
    cardsScrollY,
    [0, 2000],
    [MOBILE_IMAGE_SCALE_START, MOBILE_IMAGE_SCALE_END],
    { clamp: false },
  )

  /* Mobile */
  const yOffset = useTransform(cardsScrollY, [0, 2000], [0, Y_OFFSET_MAX], { clamp: false })
  const [handRef, handRefInView] = useInView({ threshold: 0.2 })

  const titleControls = useAnimation()
  const [titleRef, titleRefInView] = useInView({ threshold: 0.3 })

  const mobileHandWrapperControls = useAnimation()
  const mobileHandControls = useAnimation()
  const descriptionControls = useAnimation()

  /* Desktop */
  const wrapperControls = useAnimation()
  const overlayControls = useAnimation()
  const handControls = useAnimation()
  const leftWrapperControls = useAnimation()
  const rightWrapperControls = useAnimation()

  const desktopScale = useTransform(
    cardsScrollY,
    [2100, 2700],
    [DESKTOP_IMAGE_SCALE_START, DESKTOP_IMAGE_SCALE_END],
    { clamp: false },
  )

  const leftWrapperRef = useRef<HTMLDivElement>(null)
  const rightWrapperRef = useRef<HTMLDivElement>(null)

  const [isStopped, setIsStopped] = useState(false)
  const [prismicData, setPrismicData] = useState<any>(null)

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const productItemId =
        locale === 'en'
          ? 'ZnKsSxEAACEAyEfi'
          : locale === 'fi'
            ? 'ZrDI2xIAACMA4Rtt'
            : 'ZuqLehIAACMAmGTe' // sv

      const data =
        await prismicClient.getByID(productItemId, {
          lang: prismicLocale,
        })
      setPrismicData(data)
    }

    getPrismicData()
  }, [locale])

  useEffect(() => {
    const sequence = async () => {

      await mobileHandWrapperControls.start({
        scale: scale.get() > MOBILE_IMAGE_SCALE_END ? scale.get() : MOBILE_IMAGE_SCALE_END,
        translateY: yOffset.get(),
      })
    }

    if (handRefInView && isMobile && isReady) {
      sequence()
    }
  }, [handRefInView, isMobile, mobileHandWrapperControls, scrollY, isReady])

  useEffect(() => {
    const mobileHeroSequence = async () => {
      mobileHandControls.start({
        opacity: 1,
        transition: { duration: 0, ease: 'linear' },
      })
      mobileHandControls.start({
        y: 0,
        x: 0,
        transition: { duration: 0.1, ease: 'linear' },
      })
    }

    if (isMobile && isReady) {
      mobileHeroSequence()
    }
  }, [isMobile, isReady])

  useEffect(() => {
    const mobileHeroSequence = async () => {
      await titleControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
      await descriptionControls.start({ opacity: 1, transition: { duration: 0.3 } })
    }

    if (isMobile && titleRefInView && isReady) {
      mobileHeroSequence()
    }
  }, [isMobile, titleRefInView, isReady])

  useEffect(() => {
    const desktopSequence = async () => {
      await wrapperControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.6 } })
    }
    if (!isMobile) {
      desktopSequence()
    }
  }, [isMobile, wrapperControls])

  useEffect(() => {
    const sequence = async () => {
      const scale =
        desktopScale.get() > DESKTOP_IMAGE_SCALE_END
          ? desktopScale.get() > DESKTOP_IMAGE_SCALE_START
            ? DESKTOP_IMAGE_SCALE_START
            : desktopScale.get()
          : DESKTOP_IMAGE_SCALE_END
      rightWrapperControls.start({
        scale,
      })

      // get intermediate horizontal shift of the right wrapper with interpolation function:
      // y = y0 + (x - x0) * ((y1 - y0) / (x1 - x0))
      // In this case:
      // y0 = DESKTOP_IMAGE_VERTICAL_SHIFT_START
      // x0 = DESKTOP_IMAGE_SCALE_START
      // y1 = DESKTOP_IMAGE_VERTICAL_SHIFT_END
      // x1 = DESKTOP_IMAGE_SCALE_END
      // x = scale
      // hence:

      // same logic for margin left shift
      const marginLeftShift =
        DESKTOP_IMAGE_HORIZONTAL_SHIFT_START +
        (scale - DESKTOP_IMAGE_SCALE_START) *
        ((DESKTOP_IMAGE_HORIZONTAL_SHIFT_END - DESKTOP_IMAGE_HORIZONTAL_SHIFT_START) /
          (DESKTOP_IMAGE_SCALE_END - DESKTOP_IMAGE_SCALE_START))

      if (rightWrapperRef.current) {
        rightWrapperRef.current.style.marginLeft = `${marginLeftShift}px`
      }

      if (scale === DESKTOP_IMAGE_SCALE_END) {
        if (!isStopped) {
          document.body.style.overflowY = 'hidden'
          setTimeout(() => {
            document.body.style.overflowY = 'auto'
            setIsStopped(true)
          }, 500)
        }
        leftWrapperControls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 0.3, ease: 'linear' },
        })
        overlayControls.start({ zIndex: 2 })
        handControls.start({
          opacity: 1,
          transition: { duration: 0, ease: 'linear' },
        })
        handControls.start({
          y: 0,
          x: 0,
          transition: { duration: 0.1, ease: 'linear' },
        })

      } else {

        handControls.start({
          opacity: 0,
          transition: { duration: 0.1, ease: 'linear' },
        })
        handControls.start({
          y: CIRCLE_FINAL_SIZE / 2,
          x: CIRCLE_FINAL_SIZE / 2,
          transition: { duration: 0.1, ease: 'linear' },
        })
        leftWrapperControls.start({
          opacity: 0,
          y: DESKTOP_ANIMATION_SHIFT,
          transition: { duration: 0.1, ease: 'linear' },
        })
        overlayControls.start({ zIndex: -1, transition: { duration: 0, delay: 0.1} })

      }
    }

    if (!isMobile) {
      sequence()
    }
  }, [isMobile, rightWrapperControls, scrollY])

  if (isMobile && isReady) {
    return (
      <MobileWrapper>
        <MobileCards
          ref={handRef}
          animate={mobileHandWrapperControls}
          initial={{ y: DESKTOP_ANIMATION_SHIFT, scale: MOBILE_IMAGE_SCALE_START }}
          src="/images/support_local.png"
        />
        <Stack gap={7.5}>
          <MobileSubTitle ref={titleRef} animate={titleControls} initial={{ opacity: 0 }}>
            {prismicData?.data.product_header[0]?.text}
          </MobileSubTitle>
          <MobileDescription animate={descriptionControls} initial={{ opacity: 0 }}>
            {prismicData?.data.product_description[0]?.text}
          </MobileDescription>
        </Stack>
      </MobileWrapper>
    )
  }
  return (
    <Wrapper animate={wrapperControls} initial={{ y: -DESKTOP_ANIMATION_SHIFT, opacity: 0 }}>
      <Inline verticalalign={Alignments.Center} align={Alignments.Center} gap={15}>
        <LeftWrapper
          ref={leftWrapperRef}
          animate={leftWrapperControls}
          initial={{ y: DESKTOP_ANIMATION_SHIFT, opacity: 0 }}
        >
          <Stack gap={7.5} align={Alignments.Left}>
            <SubTitle>{prismicData?.data.product_header[0]?.text}</SubTitle>
            <Description>{prismicData?.data.product_description[0]?.text}</Description>
          </Stack>
        </LeftWrapper>
        <RightWrapper
          ref={rightWrapperRef}
          animate={rightWrapperControls}
          initial={{ scale: DESKTOP_IMAGE_SCALE_START }}
        >
          <Inline gap={0}>
              <HandCircle width={CIRCLE_FINAL_SIZE} />
              <HandOverlay
                animate={overlayControls}
                initial={{ zIndex: -1 }}
              />
              <HandWrapper>
                <Hand
                  src="/images/hand.png"
                  animate={handControls}
                  initial={{ x: CIRCLE_FINAL_SIZE / 2, y: CIRCLE_FINAL_SIZE / 2 , opacity: 0 }} />
              </HandWrapper>
          </Inline>
        </RightWrapper>
      </Inline>
    </Wrapper>
  )
}
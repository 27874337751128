import tw from 'twin.macro'
import React, { useEffect, useRef } from 'react'
import { Inline, Stack } from '../../common/components/Spacing'
import { Alignments, ComponentVariants, Sizes } from '../../common/constants'
import { HeroTitle } from '../../common/styled'
import { Button } from '../../common/components/Buttons'
import { useIsMobile } from '../../common/hooks'
import { motion, useAnimation } from 'framer-motion'
import { useWindowHeight } from '@react-hook/window-size'
import { useInView } from 'react-intersection-observer'

const Wrapper = tw(motion.div)`mt-[250px] text-center w-[800px] mx-auto relative z-10 mb-[70px]`

const HeroDescription = tw.h2`text-xl text-[20px] font-light m-0 w-[600px] mx-auto`
const HeroButtons = tw.div``

const MobileWrapper = tw.div`relative z-10 mt-[125px] w-full`
const MobileHeroTitle = tw(HeroTitle)`text-[40px] leading-[105%] text-center w-full`
const MobileDescription = tw(HeroDescription)`text-[14px] leading-[120%] w-full`

type HeroSectionProps = {
  setHeroSectionAppeared: (appeared: boolean) => void
  translations: {
    heroTitle: string
    heroDescription: string
    visitHelpDesk: string
    contactEmail: string
    contactPhone: string
  }
}
export const HeroSection = ({ setHeroSectionAppeared, translations }: HeroSectionProps) => {
  const isMobile = useIsMobile()
  const desktopControls = useAnimation()

  const mobileTitleControls = useAnimation()
  const mobileDescriptionControls = useAnimation()

  useEffect(() => {
    const mobileHeroSequence = async () => {
      await mobileTitleControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
      await mobileDescriptionControls.start({ opacity: 1, transition: { duration: 0.3 } })
      setHeroSectionAppeared(true)
    }

    const desktopHeroSequence = async () => {
      await desktopControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
      setHeroSectionAppeared(true)
    }
    if (isMobile) {
      mobileHeroSequence()
    } else {
      desktopHeroSequence()
    }
  }, [isMobile])

  const salesEmail = 'sales@zevoy.com'
  if (isMobile) {
    return (
      <MobileWrapper>
        <Stack align={Alignments.Left} gap={5}>
          <MobileHeroTitle animate={mobileTitleControls} initial={{ opacity: 0 }}>
            {translations.heroTitle}
          </MobileHeroTitle>
          <motion.div animate={mobileDescriptionControls} initial={{ opacity: 0 }}>
            <Stack gap={5}>
              <MobileDescription>{translations.heroDescription}</MobileDescription>
              <HeroButtons>
                <Stack gap={2.5}>
                  <Inline gap={2.5} align={Alignments.Center}>
                    <Button variant={ComponentVariants.Light} size={Sizes.Small}>
                      <a href={`mailto:${translations.contactEmail}`} style={{ transition: 'none', color: 'inherit' }}>
                        {translations.contactEmail}
                      </a>
                    </Button>
                    <Button variant={ComponentVariants.Light} size={Sizes.Small}>
                      <a href={`mailto:${salesEmail}`} style={{ transition: 'none', color: 'inherit' }}>
                        {salesEmail}
                      </a>
                    </Button>
                  </Inline>
                  <Inline gap={2.5} align={Alignments.Center}>
                    <Button variant={ComponentVariants.Light} size={Sizes.Small}>
                      <a href={`tel:${translations.contactPhone}`} style={{ transition: 'none', color: 'inherit' }}>
                        {translations.contactPhone}
                      </a>
                    </Button>
                    <Button variant={ComponentVariants.Light} size={Sizes.Small}>
                      <a href="https://helpdesk.zevoy.com/" target="_blank" rel="noreferrer">
                        {translations.visitHelpDesk}
                      </a>
                    </Button>
                  </Inline>
                </Stack>
              </HeroButtons>
            </Stack>
          </motion.div>
        </Stack>
      </MobileWrapper>
    )
  }
  return (
    <Wrapper animate={desktopControls} initial={isMobile ? { opacity: 0 } : { opacity: 0, y: -24 }}>
      <Stack align={Alignments.Center} gap={10}>
        <HeroTitle>{translations.heroTitle}</HeroTitle>
        <HeroDescription>{translations.heroDescription}</HeroDescription>
        <Inline gap={2.5}>
          <Button variant={ComponentVariants.Light} size={Sizes.Small} >
            <a href={`mailto:${translations.contactEmail}`} style={{ transition: 'none', color: 'inherit' }}>
              {translations.contactEmail}
            </a>
          </Button>
          <Button variant={ComponentVariants.Light} size={Sizes.Small} >
            <a href={`mailto:${salesEmail}`} style={{ transition: 'none', color: 'inherit' }}>
              {salesEmail}
            </a>
          </Button>
          <Button variant={ComponentVariants.Light} size={Sizes.Small} notClickable>
            {translations.contactPhone}
          </Button>
          <Button variant={ComponentVariants.Light} size={Sizes.Small}>
            <a href="https://helpdesk.zevoy.com/" target="_blank" rel="noreferrer" style={{ transition: 'none', color: 'inherit' }}>
              {translations.visitHelpDesk}
            </a>
          </Button>
        </Inline>
      </Stack>
    </Wrapper>
  )
}
